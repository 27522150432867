import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation
} from "react-router-dom";
import axios from 'axios'
import Cookies from 'js-cookie'
import { Text, Link, PrimaryButton, MessageBar, MessageBarType, Checkbox, Modal, DialogFooter, DefaultButton } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import uuid from 'uuid-random';

import ReactDOM from 'react-dom';
import * as msal from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: '9e0d5428-0a10-44f2-972c-26000e5118d7',
        authority: 'https://login.microsoftonline.com/common',
        redirectUri: window.location.origin + '/login'
    }
};
const msalInstance = new msal.PublicClientApplication(msalConfig);

export const LoginPage: React.FunctionComponent = () => {

  const history = useHistory()
  const location = useLocation()
  const synergyApi = process.env.REACT_APP_SYNERGY_API_URL
  const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : process.env.REACT_APP_DOMAIN;
  const membershipApi = process.env.REACT_APP_MEMBERSHIP_API_URL;

  const [alert, setAlert] = useState({message: "", type: "", status: undefined})
  const [loggingIn, setLoggingIn] = useState(false)
  const [staySignedIn, setStaySignedIn] = useState(true);
  const sessionDuration = 30 //Same duration as the token
  const [sso, setSSO] = React.useState(false);
  const [logo, setLogo] = React.useState('');
  const [domainChosen, setDomainChosen] = React.useState(false);
  const [companyDomain, setCompanyDomain] = React.useState('');
  const [gettingServerDomain, setGettingServerDomain] = React.useState(false);
  const [user, setUser] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [customServerDomain, setCustomServerDomain] = React.useState('');
  const [signingAAD, setsigningAAD] = React.useState(false);

  var _username:string, _password:string;

  useEffect(()=>{
    if (window.location.host !== 'account.dev.synergyos.com' && window.location.host !== 'account.app.synergyos.com') {
      let company = process.env.NODE_ENV === "development" ? "dev.synergy.page" : window.location.host.split('.').slice(1).join('.');
      getServerDomain(company, true)
    }
  },[])

  function addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  }

  function toTimestamp(strDate: any){
    var datum = Date.parse(strDate);
    return datum;
 }

  const openOfficeOnline = (token: string, fileId: string, action: string) => {
    setAlert({message: "", type: "", status: undefined})
    setLoggingIn(true)

    const headersOptions = {
        headers: {Authorization: token}
    };
  
    var wopiApi = process.env.REACT_APP_WOPI_API_URL;

    console.log(Cookies.get('serverdomain'))

    if(Cookies.get('serverdomain') === 'us-prod.synergy.page') wopiApi = "https://wopi-alpha.synergy.page"
    else if(Cookies.get('serverdomain') === 'eu-dev.synergy.page') wopiApi = "https://wopi-beta.synergy.page"
    else if(Cookies.get('serverdomain') === 'eu-prod.synergy.page') wopiApi = "https://wopi.synergy.page"

    console.log("WOPI API", wopiApi)

    return axios.post(wopiApi +`/wopi/access_info_url?fileId=${fileId}&action=${action}`, {}, headersOptions)
      .then(response => {
        var url = response.data
        window.open(url, "_self")
      }).catch(error => {
        console.log(error)
        setLoggingIn(false)
        setAlert({
          type: "danger",
          message: error.response.data.message,
          status: error.response.data.status
        })
    });
  }

  const loginAAD = (token, companyDomain, staySignedIn) => {
    let requestOptions = {
      "domain": companyDomain,
      "token": token
  }

    let serverDomain = customServerDomain

    return axios.post(synergyApi + serverDomain + membershipApi + '/v2/public/azure/exchange-token', requestOptions)
    .then(response => {
      
      let headersOptions = {
        headers: { 'Authorization': response.data.token }
      };
      
      let requestOptions = {
          "application": "Drive online",
          "domain": companyDomain,
          "origin": getInstanceId(),
          "staySignedIn": staySignedIn
      };

      return axios.post(synergyApi + serverDomain + membershipApi + '/v2/azure/login', requestOptions, headersOptions)
      .then(response => {
          return response;
      }).catch(error => {
        if(error.response && error.response.data) {
          setAlert({
            type: "danger",
            message: error.response.data.message,
            status: error.response.data.status
          })
        } else {
          setAlert({
            type: "danger",
            message: "Server not found. Please try again.",
            status: undefined
          })
        }
      });
    }).catch(error => {
      if(error.response && error.response.data) {
        setAlert({
          type: "danger",
          message: error.response.data.message,
          status: error.response.data.status
        })
      } else {
        setAlert({
          type: "danger",
          message: "Server not found. Please try again.",
          status: undefined
        })
      }
    });
  }

  const loginPopup = () => {
    try {
      setsigningAAD(true);
      msalInstance.loginPopup().then((response) => {
          if (response && response.accessToken) {
              loginAAD(response.accessToken, companyDomain, staySignedIn).then((response)=>{
                  if (response && response.status === 201) {
                    setsigningAAD(false);
                    afterLogin(response, customServerDomain, staySignedIn)
                  }
                  else {
                    setsigningAAD(false);
                  }
              })
          }
          else {
            setsigningAAD(false);
          }
      }).catch((error) => {
        setsigningAAD(false);
          console.log(error)
      });
    } catch (error) {
        setsigningAAD(false);
        console.log(error)
    }
}

function getInstanceId() {

  var instanceId = Cookies.get('instanceId')
  
  if(!instanceId) {
      instanceId = "Origin-" + uuid()
      Cookies.set("instanceId", instanceId,{ domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
  }
  
  if (!Cookies.get('instanceId')) {
      Cookies.set("instanceId", instanceId,{ domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
  }

  return instanceId;
}

  function verifyEmailCode(token:string, code:string, staySignedIn:boolean, serverDomain: string) {
    const headersOptions = {
        headers: { 'Authorization': token }
    };

    const requestOptions = {
      "application": "Drive Online",
      "key": code,
      "origin": getInstanceId(),
      "staySignedIn": staySignedIn
    };
    
    return axios.post(synergyApi + serverDomain + membershipApi +'/v2/auth/2fa/validate', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        Cookies.remove('token', { domain: domain, sameSite: 'none', secure: true})
        Cookies.remove('tokenttl', { domain: domain, sameSite: 'none', secure: true})
        Cookies.remove('serverdomain', { domain: domain, sameSite: 'none', secure: true})
        throw error
    });
  }
  
  function resendVerifyEmailCode(email, password, serverDomain) {
    const requestOptions = {
      "domain": companyDomain,
      "email": email,
      "password": password
    }
  
    return axios.post(synergyApi + serverDomain + membershipApi + '/v2/public/auth/2fa/resend', requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        return error;
    });
  }
  
  function afterLogin(response, serverDomainUrl, staySignedIn){
    var token = response.data.accessToken;
    var daysToExpire = 9
    var expirationDate = addDays(new Date(), daysToExpire)
    var tokenttl = toTimestamp(expirationDate)
    var cookies = Cookies.withConverter({
      write: function (value, name):any {
        return value;
      }
    })
    if (staySignedIn) {
      Cookies.set("refreshKey", response.data.refreshToken,{ domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    }
    cookies.set('token', token, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    Cookies.set('tokenttl', tokenttl.toString(), { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    Cookies.set("serverdomain", serverDomainUrl, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    
    setLoggingIn(false)

    const params = new URLSearchParams(location.search);
    const wopiFile = params.get('wopifile');
    const wopiAction = params.get('wopiaction')

    if(wopiFile && wopiAction) openOfficeOnline(token, wopiFile, wopiAction)
    else history.push("/")

    return response;
  }

  const getServerDomain = (company, start = false) => {
    setGettingServerDomain(true);
    setLogo('');
    axios.get('https://synergy-api.common.synergy.page/server-info/public/'+(start ? company : 'company/' + company ))
    .then(response =>{
      setGettingServerDomain(false);
      setCustomServerDomain(response.data.serverDomain)
      setSSO(response.data.sso)
      setLogo(response.data.imageLarge)
      setDomainChosen(true);
      setCompanyDomain(response.data.companyDomain)
    }).catch((error)=>{
      setAlert({
        type: "danger",
        message: "Could not get server info",
        status: undefined
      })
      setGettingServerDomain(false);
      throw(error)
    })
  }

  function goBackToCompanyDomain(e) {
      e.preventDefault();
      localStorage.removeItem('companyDomain')
      setGettingServerDomain(false);
      setLogo('');
      setDomainChosen(false);
      setSSO(false);
      setCompanyDomain('');
      setsigningAAD(false);
  }

  const onSubmit = (e: any) => {
    if (e) e.preventDefault();
    setAlert({message: "", type: "", status: undefined})
    setLoggingIn(true)

    _username = user;
    _password = password;
    const requestOptions = {
        'application': 'Drive Online',
        'domain': companyDomain,
        'email': user,
        'origin': getInstanceId(),
        'password': password,
        'staySignedIn': staySignedIn
    }

    var serverDomainUrl: string = customServerDomain;

    return axios.post(synergyApi + serverDomainUrl + membershipApi + '/v2/public/auth/login', requestOptions, {})
      .then(response => {
        if (response.data['2fa']) {
          setLoggingIn(false)
          _askUserForCode(response.data['2faToken'],serverDomainUrl).then((result) => {
            afterLogin(result, serverDomainUrl, staySignedIn);
          }).catch(reject => {console.log(reject)})
        }
        else {
          afterLogin(response, serverDomainUrl, staySignedIn);
        }
      }).catch(error => {
        console.log(error.response)
        setLoggingIn(false)
        if(error.response && error.response.data) {
          setAlert({
            type: "danger",
            message: error.response.data.message,
            status: error.response.data.status
          })
        } else {
          setAlert({
            type: "danger",
            message: "Server not found. Please try again.",
            status: undefined
          })
        }
    });
  }

  const _askUserForCode = async (_token:string,serverDomain:string) => {
    return await new Promise((resolve, reject) => {
      var showDialog = true;
      var code = '';
      var warning: string = "";
      var token: string = _token;

      var confirm = () => {
        warning = "";
        verifyEmailCode(token,code,staySignedIn,serverDomain).then((response)=>{
            resolve(response)
            setTimeout(()=>{
                showDialog = false;
                renderDialog()
            },600)
        }).catch(()=>{
            warning = "invalidCode";
            renderDialog()
        })
      }

      var cancel = () => {
        showDialog = false;
        renderDialog()
        reject("Operation rejected by user");
      }

      var resend = () => {
        renderDialog()
        resendVerifyEmailCode(_username, _password, serverDomain).then((response)=>{
            if (response.status === 201) {
                warning = "emailSent";
                token = response.data['2faToken']
                renderDialog();
            } else {
                warning = "cantResend";
                renderDialog();
            }
        })
      }

      const handleInput = (value)=> {
        code = value;
        renderDialog();
      }

      var div = document.createElement('div');
      var renderDialog = () => {
        ReactDOM.render(
          <Modal
            onDismiss={cancel}
            isOpen={showDialog}
            isBlocking={true}
            className="modal-wrapper"
            isDarkOverlay={false}
            isModeless={true}
            containerClassName="form-modal p-5"
            scrollableContentClassName="scrollable-modal-content"
            styles={{root:{zIndex:9999999}}}
            >
              <div className="w-100">
                <Text variant={'xLarge'} className="mt-4 lead">
                  Two-Factor Authentication
                </Text>
                {   warning === "invalidCode" ?
                    <MessageBar
                      styles={{root:{marginTop: '1rem'}}}
                      messageBarType={MessageBarType.error}
                      isMultiline={true}
                    >Expired or incorrect code.</MessageBar>
                    : warning === "cantResend" ?
                    <MessageBar
                      styles={{root:{marginTop: '1rem'}}}
                      messageBarType={MessageBarType.error}
                      isMultiline={true}
                    >Email with verification code could not be sent.</MessageBar>
                    : warning === "emailSent" ?
                    <MessageBar
                      styles={{root:{marginTop: '1rem'}}}
                      messageBarType={MessageBarType.success}
                      isMultiline={true}
                    >Email with verification code sent.</MessageBar>
                    : null
                }
                <p className='mt-3'>An email with a verification code was just sent to your email</p>
                <TextField
                    placeholder='Enter code'
                    className=''
                    value={code}
                    onChange={(e,v)=>handleInput(v)}
                    onSubmit={confirm}
                />
                <div className="mt-4 fake-url" onClick={resend}>
                    <p>Resend verification email</p>
                </div>
                
                <DialogFooter>
                  <DefaultButton onClick={cancel} text='Cancel' />
                  <PrimaryButton autoFocus onClick={ confirm } text='Sign in' />
                </DialogFooter>
              </div>
            </Modal>
          , div
        )
      }

      renderDialog();
    })
}

  function _goToForgotPassword() {
    history.push('/reset-password');
  } 

  return (
    <div>
      { !domainChosen ?
        <Modal
            isOpen={true}
            isBlocking={true}
            className="modal-wrapper"
            isDarkOverlay={false}
            isModeless={true}
            containerClassName="form-modal p-5"
            scrollableContentClassName="scrollable-modal-content"
        >
            <Text variant={'xLarge'} className="lead">
                {'Company domain'}
            </Text>

            { alert && alert.message &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
                onDismiss={ () => setAlert({message: "", type: "", status: undefined}) }
                dismissButtonAriaLabel="Close"
              >
                { alert.status === 401 ?  "Your email or password is incorrect. If you don't remember your password, contact your account administrator." 
                : alert.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                : alert.message }
              </MessageBar>
            }

            <form name="form" onSubmit={(e)=>{e.preventDefault();getServerDomain(companyDomain)}} className="mt-2">
                <TextField type="text" placeholder={'Example: synergyos.com'} name="companyDomain" value={companyDomain} onChange={(e,v)=>setCompanyDomain(v || '')} underlined required autoFocus autoComplete="companyDomain" className="required-hidden mb-4" />

                <Link href='https://synergyos.com/support' variant={'small'}>
                    {"Can't access your account?"}
                </Link>

                <div className="form-group text-right mt-3 mb-0">
                    <PrimaryButton text={'Next'} disabled={gettingServerDomain} type="submit"/>
                    {gettingServerDomain &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loading..." className="ml-2 "/>
                    }
                </div>
            </form>
        </Modal>
    : !sso ?
        <Modal
            isOpen={true}
            isBlocking={true}
            className="modal-wrapper"
            isDarkOverlay={false}
            isModeless={true}
            containerClassName="form-modal p-5"
            scrollableContentClassName="scrollable-modal-content"
        >
            <p className="lead m-0">
                {'Company domain'}
            </p>
            <a href={'https://' + companyDomain}>{companyDomain}</a>
            
            { logo && logo.length > 0 ?
                <div className="d-flex pt-2 mt-4 mb-3 w-100">
                    <div className="text-center w-100">
                        <img src={'data:image/png;base64,'+logo } className="logo img-fluid" style={{width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px'}} alt={ "Company logo"} />
                    </div>
                </div>
            : null }

            <p className="lead mt-4">
                {'Sign in'}
            </p>


            { alert && alert.message &&
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              onDismiss={ () => setAlert({message: "", type: "", status: undefined}) }
              dismissButtonAriaLabel="Close"
            >
              { alert.status === 401 ?  "Your email or password is incorrect. If you don't remember your password, contact your account administrator." 
              : alert.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
              : alert.message }
            </MessageBar>
          }

            <form name="form" onSubmit={onSubmit}>
                <TextField type="email" placeholder={'Email'} name="username" value={user} onChange={(e,v)=>setUser(v || '')} underlined required autoFocus autoComplete="username" className="required-hidden mb-3" />
                <TextField type="password" placeholder={'Password'} name="password" value={password} onChange={(e,v)=>setPassword(v || '')} underlined required canRevealPassword revealPasswordAriaLabel="Show password" className="required-hidden mb-3" />

                <Checkbox
                    className='mt-4 mb-2'
                    label={'Stay signed in'}
                    checked={staySignedIn}
                    onChange={()=>setStaySignedIn(!staySignedIn)}
                />

                <Link variant={'small'} onClick={_goToForgotPassword.bind(this)}>
                    {'Forgot your password?'}
                </Link>

                <div className="form-group text-right mt-4 mb-0">
                    {(window.location.host === 'account.dev.synergyos.com' || window.location.host === 'account.app.synergyos.com') && <DefaultButton className='mr-2 cancel-button' onClick={goBackToCompanyDomain.bind(this)} text={'Back'}/>}
                    <PrimaryButton text={'Sign in'} disabled={loggingIn} type="submit"/>
                    {loggingIn &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loading..." className="ml-2 "/>
                    }
                </div>
            </form>
        </Modal>
    :
        <Modal
            isOpen={true}
            isBlocking={true}
            className="modal-wrapper"
            isDarkOverlay={false}
            isModeless={true}
            containerClassName="form-modal p-5"
            scrollableContentClassName="scrollable-modal-content"
        >
            <p className="lead m-0">
                {'Company domain'}
            </p>
            <a href={'https://' + companyDomain}>{companyDomain}</a>
            
            { logo && logo.length > 0 ?
                <div className="d-flex pt-2 mt-4 mb-3 w-100">
                    <div className="text-center w-100">
                        <img src={'data:image/png;base64,'+logo } className="logo img-fluid" style={{width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100px'}} alt={ "Company logo"} />
                    </div>
                </div>
            : null }

            <p className="lead mt-4 mb-5">
                {'Sign in'}
            </p>


            { alert && alert.message &&
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
                onDismiss={ () => setAlert({message: "", type: "", status: undefined}) }
                dismissButtonAriaLabel="Close"
                className='my-1'
              >
                { alert.status === 401 ?  "Your email or password is incorrect. If you don't remember your password, contact your account administrator." 
                : alert.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                : alert.message }
              </MessageBar>
            }

            <form name="form">
                <div className="text-center mb-0">
                    <DefaultButton onClick={()=>loginPopup()} disabled={signingAAD} style={{border: '1px solid #bfbfbf', height: '32px', padding: '18px 50px'}}>
                        { signingAAD? 
                          <>
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="  style={{marginRight: '15px'}} alt="Loading..." />
                            <span style={{fontWeight: 600, color: '#5e5e5e'}}>{'Sign in with Microsoft'}</span>
                          </>
                        : <>
                            <img src={ process.env.PUBLIC_URL + "/ms-icon.svg" } style={{marginRight: '10px'}} alt="Microsoft logo" />
                            <span style={{fontWeight: 600, color: '#5se5e5e'}}>{'Sign in with Microsoft'}</span>
                          </>
                        }
                    </DefaultButton>
                </div>
                <Checkbox
                    className='mt-5'
                    label={'Stay signed in'}
                    checked={staySignedIn}
                    onChange={()=>setStaySignedIn(!staySignedIn)}
                />
                <Link onClick={()=>{setSSO(false)}} className='mt-4 mb-0 pb-0'>Click here to sign in with your Synergy credentials instead</Link>
                {(window.location.host === 'account.dev.synergyos.com' || window.location.host === 'account.app.synergyos.com') && <div className="form-group text-right mt-4 mb-0">
                  <DefaultButton className='mr-2 cancel-button' onClick={goBackToCompanyDomain.bind(this)} text={'Back'}/>
                </div>}

            </form>
        </Modal>
    }
    </div>

);
}