import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation
} from "react-router-dom";
import axios from 'axios'
import { useForm, Controller } from "react-hook-form";
import { Text, Image, Stack, PrimaryButton, MessageBar, MessageBarType, Label } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

import icon from '../_img/icon_synergy.png';

export const SetPasswordPage: React.FunctionComponent = () => {

  const location = useLocation()

  const { handleSubmit, control } = useForm();
  
  const [alert, setAlert] = useState<any>(null)
  const [gettingData, setGettingData] = useState(false)
  const [newPasswordSuccess, setNewPasswordSuccess] = useState(false)
  const [logo, setLogo] = useState();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const domain = params.get('domain')

  const synergyApi = process.env.REACT_APP_SYNERGY_API_URL
  var membershipApi = process.env.REACT_APP_MEMBERSHIP_API_FULL_URL;
  if (domain) membershipApi = synergyApi + domain + process.env.REACT_APP_MEMBERSHIP_API_URL;

  useEffect(()=>{
    if (window.location.host !== 'account.dev.synergyos.com' && window.location.host !== 'account.app.synergyos.com') {
      let company = process.env.NODE_ENV === "development" ? "dev.synergy.page" : window.location.host.split('.').slice(1).join('.');
      axios.get('https://synergy-api.common.synergy.page/server-info/public/'+company)
      .then(response =>{
        setLogo(response.data.imageLarge)
      })
    }
  },[])

  const passwordSet = (data: any ) => {
    setGettingData(true)
    setAlert({message: "", type: "", status: undefined})

    const { password, confirmPassword } = data;
    const requestOptions = {
      password: password && password.length > 0 ? password : null,
      confirmPassword: confirmPassword && confirmPassword.length > 0 ? confirmPassword : null
    }
    const headerOptions = {
      headers: {
        'Authorization': token
      }
    }

    return axios.post(membershipApi + '/v2/users/set-password', requestOptions, headerOptions)
      .then(response => {
        setAlert({
          type: MessageBarType.success,
          message: "You have successfully set a new password.",
          status: 200
        })

        setGettingData(false)
        setNewPasswordSuccess(true)
        return response.data;
      }).catch(error => {
        console.log(error.response)
        setGettingData(false)
        setAlert({
          type: MessageBarType.error,
          message: error.response.data.message,
          status: error.response.data.status
        })
    });
  }

  if (typeof window === `undefined`) {
    return(<></>);
  } 

  function gotToDrive() {
    let company = process.env.NODE_ENV === "development" ? "synergyos.com" : window.location.host.split('.').slice(1).join('.');
    window.open('https://drive.'+ company, "_self")
  }

  return (
    <>
      <Stack
        styles={{
          root: {
            boxShadow: 'rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px',
            backgroundColor: 'white',
            overflow: 'auto',
            padding: '24px 48px',
            position: 'relative',
            width: '90%'
          }
        }}
      >
        <Stack>
        { logo ?
          <div className="d-flex pt-2 mb-3 w-100">
              <div className="text-center w-100">
                  <img src={'data:image/png;base64,'+logo } className="logo img-fluid" style={{width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '96px'}} alt={ "Company logo"} />
              </div>
          </div>
        : null }
        </Stack>

        <Stack tokens={{childrenGap: 24}}>
          { newPasswordSuccess ?
            <Stack
              styles={{
                root: {
                  textAlign:'center',
                  width:'100%'
                }
              }}
            > 
              <Text className='mb-5' variant='large'>Password changed successfully!</Text>
              <PrimaryButton text={ "Sign in" } disabled={gettingData}
                onClick={ () => gotToDrive() }
                styles={{
                  root: {
                    margin: '0 auto',
                    width: '100px'
                  }
                }}
              />
            </Stack>
          :
            <>
            { alert && alert.message &&
              <MessageBar
                messageBarType={alert.type}
                isMultiline={true}
                onDismiss={ () => setAlert({message: "", type: "", status: undefined}) }
                dismissButtonAriaLabel="Close"
                >
                { alert.message }
              </MessageBar>
            }

            <Text variant={'xLarge'} className="lead">
              Set a new password
            </Text>

            { !token ?
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
                >
                No account token is present. Please, try again.
              </MessageBar>
            : null}

            <form name="form" onSubmit={handleSubmit(passwordSet)} className="mt-3">
                <Stack tokens={{childrenGap: 12}}
                styles={{
                    root: {
                      textAlign: 'left'
                    }
                  }}
                >
                    <Controller
                        control={control}
                        name="password"
                        render={({ field }) => (
                          <>
                            <Label className='pb-0 mb-0'>Password:</Label>
                            <TextField {...field} underlined type="password" placeholder="Password" defaultValue="" required styles={{root: {label: {width: '150px'}}, field: {padding: 0}}} canRevealPassword
                            revealPasswordAriaLabel="Show password" />
                          </>
                        )}
                    />

                    <Controller
                        control={control}
                        name="confirmPassword"
                        render={({ field }) => (
                          <>
                            <Label className='pt-3 pb-0 mb-0'>Confirm password:</Label>
                            <TextField {...field} underlined type="password" placeholder="Confirm password" defaultValue="" required styles={{root: {label: {width: '150px'}}, field: {padding: 0}}} canRevealPassword
                            revealPasswordAriaLabel="Show password" />
                          </>
                        )}
                    />

                    <Stack>
                      <div className='password-tip mt-3'>
                        <Text variant='small'>Passwords must include at least:</Text>
                        <ul>
                        <li><Text variant='small'>-10 characters</Text></li>
                        <li><Text variant='small'>-One digit</Text></li>
                        <li><Text variant='small'>-One uppercase letter</Text></li>
                        <li><Text variant='small'>-One special letter</Text></li>
                        </ul>

                      </div>

                    </Stack>

                    <Stack
                      styles={{
                        root: {
                          display: "flex",
                          flexDirection: 'column',
                          flexFlow: 'unset',
                          justifyContent: 'flex-end',
                          marginTop: '32px !important'
                        }
                      }}
                    >
                        <PrimaryButton text={ "Next" } disabled={gettingData || !token} type="submit"
                          styles={{
                            root: {
                              width: '100px'
                            }
                          }}
                        />
                        {gettingData &&
                          <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: '12px' } }}/>
                        }
                    </Stack>
                </Stack>
            </form>
            </>
          }
        </Stack>
      </Stack>
    </>
  );
}