import React, { useCallback, useEffect, useState } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import { Layer } from '@fluentui/react/lib/Layer';
import { Text, Link, Stack } from '@fluentui/react';
import { Image } from '@fluentui/react/lib/Image';
import { NeutralColors } from '@fluentui/theme';

import { LoginPage } from './_pages/LoginPage'
import { ActivationAndSetPasswordPage } from './_pages/ActivationAndSetPasswordPage'
import { ResetPasswordPage } from "./_pages/ResetPasswordPage";
import { SetPasswordPage } from "./_pages/SetPasswordPage";
import { AccountPage } from './_pages/AccountPage'

import logo from './_img/synergy_logo_white.png';
import favIcon from './_img/account_icon.png';

import { initializeIcons } from '@fluentui/react/lib/Icons';
import Cookies from "js-cookie";
import axios from "axios";
import { Titlebar } from "./_components/Titlebar";

var canRefreshToken = true;
var sessionDuration = 30;

function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(date.getDate() + days);
  return result;
}

function toTimestamp(strDate: any){
  var datum = Date.parse(strDate);
  return datum;
}

export const App: React.FunctionComponent = () => {
  const [_tokenSchedule, setTokenSchedule] = useState(false);
  const [canRender,setRender] = useState(false)
  const history = useHistory()
  initializeIcons()

  const checkTokenExpirationDate = useCallback(() => {
    console.log('Checking refresh token')
    let tokenttl:any = Cookies.get('tokenttl')
    let currentDate = new Date().getTime();
    let pathname = window.location.pathname;
    if (pathname.indexOf("/login") === -1) {
      if ((tokenttl - currentDate) < (24*60*60*1000)) { 
        console.log('Trying to refresh token')
        return refreshToken();
      }
      else {
        setRender(true)
      }
    }
    else {
      setRender(true)
    }
  },[])
  
  function refreshToken() {   
    var refreshKey = Cookies.get("refreshKey")
    const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : process.env.REACT_APP_DOMAIN;
    const serverDomain = Cookies.get("serverdomain") || '';
    const synergyApi = process.env.REACT_APP_SYNERGY_API_URL
    const membershipApi = process.env.REACT_APP_MEMBERSHIP_API_URL;
    if (canRefreshToken && refreshKey) {
      canRefreshToken = false;
      return axios.post(synergyApi + serverDomain + membershipApi + '/v2/public/auth/refresh-token', {refreshToken: refreshKey})
      .then((response)=>{
        console.log('Token refreshed')
        var token = response.data.accessToken;
        var daysToExpire = 9 //Used by wopi
        var expirationDate = addDays(new Date(), daysToExpire)
        var tokenttl:any = toTimestamp(expirationDate)
        Cookies.set("refreshKey", response.data.refreshToken,{ domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
        var sessionExpirationDate = new Date();
        sessionExpirationDate.setDate(sessionExpirationDate.getDate() + sessionDuration);

        var cookies = Cookies.withConverter({
            write: function (value, name):any {
            return value;
            }
        })
        cookies.set('token', token, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
        Cookies.set('tokenttl', tokenttl, { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
        Cookies.set("serverdomain", Cookies.get('serverdomain') || '', { domain: domain, expires: sessionDuration, sameSite: 'none', secure: true})
    
        setTimeout(()=>{
            canRefreshToken = true;
        },10000)
        setRender(true)
        return response.data
      }).catch((error)=>{
        setTimeout(()=>{
            canRefreshToken = true;
        },10000)
        Cookies.remove('token', { domain: domain, sameSite: 'none', secure: true})
        Cookies.remove('tokenttl', { domain: domain, sameSite: 'none', secure: true})
        Cookies.remove('serverdomain', { domain: domain, sameSite: 'none', secure: true})
        console.log('Error trying to refresh token.')
        setRender(true)
        throw error;
      })
    }
    else {
      Cookies.remove('token', { domain: domain, sameSite: 'none', secure: true})
      Cookies.remove('tokenttl', { domain: domain, sameSite: 'none', secure: true})
      Cookies.remove('serverdomain', { domain: domain, sameSite: 'none', secure: true})
      console.log('No refresh key')
      setRender(true)
    }
  }

  useEffect(()=>{
    checkTokenExpirationDate()
    if (_tokenSchedule === false) setInterval(checkTokenExpirationDate,600000)
    setTokenSchedule(false);
  },[_tokenSchedule, checkTokenExpirationDate])

  return (
    canRender ?
    <Router>
      <Layer
        styles={{
          content: {
            backgroundColor: '#2c2c2c',
            borderTop: '1px solid grey',
            height: '40px',
            display: 'flex',
            alignItems: 'center'
          }
        }}
      > 
      <Titlebar disabledSearch={true}/>
       
      </Layer>

      <Stack
          styles={{
            root: {
              backgroundColor: '#faf9f8',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              minHeight: '100vh',
              height: "100%",
              position: 'relative',
              zIndex: '1'
            }
          }}
        >
      
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          verticalFill
          styles={{
            root: {
              maxWidth: '640px',
              margin: '40px auto 0 auto',
              position: 'relative',
              textAlign: 'center',
              width: '100%'
            }
          }}
        >
          <Switch>
            <Route exact path="/login">
              <LoginPage />
            </Route>
            <Route exact path="/activation-and-set-password">
              <ActivationAndSetPasswordPage />
            </Route>
            <Route exact path="/reset-password">
              <ResetPasswordPage />
            </Route>
            <Route exact path="/set-password">
              <SetPasswordPage />
            </Route>
            <Route exact path="/">
              <AccountPage />
            </Route>
          </Switch>
        </Stack>
      </Stack>
    </Router> : null
  );
};