import React, { useState, useEffect, useCallback} from 'react';
import {
  Link,
  useHistory
} from "react-router-dom";
import axios from 'axios'
import { useForm, Controller } from "react-hook-form";
import { Text, Image, Stack, PrimaryButton, MessageBar, MessageBarType, DefaultButton } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

import icon from '../_img/icon_synergy.png';
import Cookies from 'js-cookie';

export const AccountPage: React.FunctionComponent = () => {

  const history = useHistory()
  const synergyApi = process.env.REACT_APP_SYNERGY_API_URL
  const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : process.env.REACT_APP_DOMAIN;
  const membershipApi = process.env.REACT_APP_MEMBERSHIP_API_URL;

  const { handleSubmit, control } = useForm();
  
  const [alert, setAlert] = useState<any>(null)
  const [gettingData, setGettingData] = useState(false)
  const [userData, setUserData] = useState<any>(null)
  const [showPasswordChange, setShowPasswordChange] = useState(false)
  const [companyImage, setCompanyImage] = useState(false)

  const serverDomain = Cookies.get("serverdomain") || '';
  const token = Cookies.get("token") || '';

  async function deleteCookies(cookies) {
    for (let i = 0; i < cookies.length; i++) {
        await new Promise((resolve, reject) => {
            try {
                let cookie = cookies[i];
                Cookies.remove(cookie, { domain: domain, expires: '' || undefined, sameSite: 'none', secure: true})
                Cookies.remove(cookie, { domain: 'account.' + domain, expires: '' || undefined, sameSite: 'none', secure: true})
                resolve(true)
            } catch(error) {
                reject(error)
            }
        })
    }
    if (history.location.pathname.indexOf("/login") === -1
    && history.location.pathname.indexOf("/appUpdate") === -1) {
        history.push('/login');
    }
}

  const deleteAllCookies = useCallback(() => {
    deleteCookies(["token", "tokenttl", "refreshKey"])
  }, [domain])

  const signOut = useCallback(() => {
    var headersOptions = {
      headers: { 'Authorization': Cookies.get('token') }
    };
    if (Cookies.get('token')) axios.delete(synergyApi + serverDomain + membershipApi + '/v2/auth/logout', headersOptions)
    deleteAllCookies()
  }, [history, deleteAllCookies])

  const getUserData = useCallback(() => {
    setGettingData(true)

    const requestOptions = {
      headers: {
        Authorization: token
      }
    }

    return axios.get(synergyApi + serverDomain + membershipApi + '/v2/auth/introspect-token', requestOptions)
      .then(response => {
        axios.get('https://synergy-api.common.synergy.page/server-info/public/'+response.data.repository.deploymentDomain)
        .then(response =>{
          localStorage.setItem('companyLogo1', response.data.imageLarge);
          localStorage.setItem('companyLogo2', response.data.imageMedium);
          localStorage.setItem('companyLogo3', response.data.imageSmall);
          setCompanyImage(response.data.imageLarge)
        }).catch((error)=>{
          console.log(error.response)
          signOut()
          setGettingData(false)
          setAlert({
            type: MessageBarType.error,
            message: "Could not get your account details. Please, try again.",
            status: undefined
          })
        })
        setUserData(response.data)
        setGettingData(false)
        return response.data;
      }).catch(error => {
        console.log(error.response)
        signOut()
        setGettingData(false)
        setAlert({
          type: MessageBarType.error,
          message: "Could not get your account details. Please, try again.",
          status: undefined
        })
    });
  }, [synergyApi, serverDomain, membershipApi, token, signOut])

  const updateUserData = (data: any ) => {
    setGettingData(true)
    setAlert({message: "", type: "", status: undefined})

    const { email, name, surname, oldPassword, password, confirmPassword } = data;
    const requestOptions = {
      email: email,
      name: name,
      surname: surname,
      oldPassword: oldPassword && oldPassword.length > 0 ? oldPassword : null,
      password: password && password.length > 0 ? password : null,
      confirmPassword: confirmPassword && confirmPassword.length > 0 ? confirmPassword : null,
    }

    const headerOptions = {
      headers: {
        Authorization: token
      }
    }

    return axios.put(synergyApi + serverDomain + membershipApi + '/v2/users/' + userData.user.id, requestOptions, headerOptions)
      .then(response => {
        setAlert({
          type: MessageBarType.success,
          message: "Your password has been successfully updated",
          status: 200
        })
        getUserData()
        setGettingData(false)
        setShowPasswordChange(false)
        setTimeout(()=>{
          setAlert(null)
        },5000)
        return response.data;
      }).catch(error => {
        console.log(error.response)
        setGettingData(false)
        setAlert({
          type: MessageBarType.error,
          message: error.response.data.message,
          status: error.response.data.status
        })
    });
  }

  useEffect(() => {
    if(token) getUserData()
    else signOut()
  }, [getUserData, signOut, token]);

  if (typeof window === `undefined`) {
    return(<></>);
  } 

  return (
    <>
      <Stack
        styles={{
          root: {
            boxShadow: 'rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px',
            backgroundColor: 'white',
            overflow: 'auto',
            maxWidth: '480px',
            padding: '24px 48px',
            position: 'relative',
            width: '80%'
          }
        }}
      >
        <Stack>
          {companyImage ? <Image src={companyImage ? 'data:image/png;base64,' + companyImage : icon} alt="logo" style={{maxHeight: '90px'}}
            styles={{
              root: {
                margin: '0 auto 24px auto'
              }
            }}
          /> : <div style={{height: '100px'}}></div>}
        </Stack>

        <Stack tokens={{childrenGap: 24}}>

          <Text variant={'xLarge'} className="lead">
            Your account  
          </Text>

          {userData ?
            <>
            { alert && alert.message &&
                <MessageBar
                  messageBarType={alert.type}
                  isMultiline={true}
                  onDismiss={ () => setAlert({message: "", type: "", status: undefined}) }
                  dismissButtonAriaLabel="Close"
                  >
                  { alert.status === 401 ?  "Your email or password is incorrect. If you don't remember your password, contact your account administrator." : alert.message }
                </MessageBar>
            }

            <form name="form" onSubmit={handleSubmit(updateUserData)} className="mt-3">
                <Stack tokens={{childrenGap: 12}}
                styles={{
                    root: {
                      textAlign: 'left'
                    }
                  }}
                >
                    <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                            <TextField {...field} type="text" label="Email" defaultValue={userData.user.email} borderless readOnly styles={{root: {label: {width: '150px'}}}} />
                        )}
                    />

                    {!showPasswordChange && <>
                      <Controller
                          control={control}
                          name="name"
                          render={({ field }) => (
                              <TextField {...field} type="text" label="Name" defaultValue={userData.user.name} borderless readOnly={true} required={false} styles={{root: {label: {width: '150px'}}}} />
                          )}
                      />

                      <Controller
                          control={control}
                          name="surname"
                          render={({ field }) => (
                              <TextField {...field} type="text" label="Surname" defaultValue={userData.user.surname} borderless readOnly={true} required={false} styles={{root: {label: {width: '150px'}}}} />
                          )}
                      />
                    </>}

                    {!showPasswordChange && <PrimaryButton text={ "Change password" }
                      onClick={()=>{setShowPasswordChange(true)}}
                      hidden={showPasswordChange}
                      styles={{
                        root: {
                          width: '200px',
                          margin: 'auto'
                        }
                      }}
                    />}

                    { showPasswordChange && <>
                    <Controller
                        control={control}
                        name="oldPassword"
                        render={({ field }) => (
                            <TextField {...field} type="password" label="Current password" placeholder={ "Current password" } defaultValue="" styles={{root: {label: {width: '150px'}}}} canRevealPassword
                            revealPasswordAriaLabel="Show password" />
                        )}
                    />

                    <Controller
                        control={control}
                        name="password"
                        render={({ field }) => (
                            <TextField {...field} type="password" label="New password" placeholder={ "New password" } defaultValue="" styles={{root: {label: {width: '150px'}}}} canRevealPassword
                            revealPasswordAriaLabel="Show password" />
                        )}
                    />

                    <Controller
                        control={control}
                        name="confirmPassword"
                        render={({ field }) => (
                            <TextField {...field} type="password" label="Confirm password" placeholder={ "Confirm password" } defaultValue="" styles={{root: {label: {width: '150px'}}}} canRevealPassword
                            revealPasswordAriaLabel="Show password" />
                        )}
                    />

                    <Stack
                      styles={{
                        root: {
                          display: "flex",
                          flexDirection: 'column',
                          flexFlow: 'unset',
                          justifyContent: 'flex-end',
                          marginTop: '32px !important'
                        }
                      }}
                    >
                        <DefaultButton text={ "Cancel" } disabled={gettingData} type="submit"
                        onClick={()=>setShowPasswordChange(false)}
                          styles={{
                            root: {
                              width: '100px',
                              border: 'none',
                              marginRight: '10px'
                            }
                          }}
                        />
                        <PrimaryButton text={ "Update password" } disabled={gettingData} type="submit"
                          styles={{
                            root: {
                              width: '160px'
                            }
                          }}
                        />
                        {gettingData &&
                          <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: '12px' } }}/>
                        }
                    </Stack>
                    </>}
                      <Text variant={'small'}
                        onClick={() => signOut()}
                        styles={{
                          root: {
                            marginTop: '32px !important',
                            textAlign: 'center'
                          }
                        }}
                      >
                        <Link to="/login">
                          Sign out
                        </Link>
                      </Text>
                </Stack>
            </form>
            </>
          :
            <Text variant={'large'} className="lead">
              Loading...  
            </Text>
          }
        </Stack>
      </Stack>
    </>
  );
}