import React, { useEffect, useState } from 'react';
import {
  Link
} from "react-router-dom";
import axios from 'axios'
import { useForm, Controller } from "react-hook-form";
import { Text, Image, Stack, PrimaryButton, MessageBar, MessageBarType, Label } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

import icon from '../_img/icon_synergy.png';

export const ResetPasswordPage: React.FunctionComponent = () => {
  const { handleSubmit, control } = useForm();
  
  const [alert, setAlert] = useState<any>(null)
  const [gettingData, setGettingData] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [logo, setLogo] = useState();

  useEffect(()=>{
    if (window.location.host !== 'account.dev.synergyos.com' && window.location.host !== 'account.app.synergyos.com') {
      let company = process.env.NODE_ENV === "development" ? "dev.synergy.page" : window.location.host.split('.').slice(1).join('.');
      axios.get('https://synergy-api.common.synergy.page/server-info/public/'+company)
      .then(response =>{
        setLogo(response.data.imageLarge)
      })
    }
  },[])

  const passwordReset = (data: any ) => {
    setGettingData(true)
    setAlert({message: "", type: "", status: undefined})

    const { email, companyDomain } = data;
    let company = (window.location.host === 'account.dev.synergyos.com' || window.location.host === 'account.app.synergyos.com') ? ('company/'+companyDomain) : process.env.NODE_ENV === "development" ? "company/dev.synergy.page" : window.location.host.split('.').slice(1).join('.');
    axios.get('https://synergy-api.common.synergy.page/server-info/public/'+company)
    .then(response =>{
      var serverDomainUrl: string = response.data.serverDomain;
      const requestBody = {
        email: email,
        repositoryDomain: response.data.companyDomain,
        infrastructureDomain: serverDomainUrl
      }
      return axios.post('https://synergy-api.'+ serverDomainUrl +'/membership/v2/public/users/reset-password', requestBody)
        .then(response => {
          setAlert({
            type: MessageBarType.success,
            message: "Success! We have sent you an email with the instructions to reset your password.",
            status: 200
          })
          setEmailSent(true)
          setGettingData(false)
          return response.data;
        }).catch(error => {
          console.log(error.response)
          setGettingData(false)
          if (error.response && error.response.data && error.response.data.message) {
            setAlert({
              type: MessageBarType.error,
              message: error.response ? error.response.data.message : "Can't connect to server",
              status: error.response ? error.response.data.status : 404
            })
          } else {
            setAlert({
              type: MessageBarType.error,
              message: "Can't connect to server",
              status: 404
            })
          }

      });
    }).catch((error)=>{
      setAlert({
        type: MessageBarType.error,
        message: "Could not get server info",
        status: undefined
      })
      throw(error)
    })
  }

  if (typeof window === `undefined`) {
    return(<></>);
  } 

  function gotToDrive() {
    let company = process.env.NODE_ENV === "development" ? "synergyos.com" : window.location.host.split('.').slice(1).join('.');
    window.open('https://drive.'+ company, "_self")
  }

  return (
    <>
      <Stack
        styles={{
          root: {
            boxShadow: 'rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px',
            backgroundColor: 'white',
            overflow: 'auto',
            padding: '24px 48px',
            position: 'relative',
            width: '90%'
          }
        }}
      >
        <Stack>
        { logo ?
          <div className="d-flex pt-2 mb-3 w-100">
              <div className="text-center w-100">
                  <img src={'data:image/png;base64,'+logo } className="logo img-fluid" style={{width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '96px'}} alt={ "Company logo"} />
              </div>
          </div>
        : null }
        </Stack>
        { emailSent ?
        <Stack
          styles={{
            root: {
              textAlign:'center',
              width:'100%'
            }
          }}
        >
          <Text className='mt-2 mb-5' variant='large'>Success! We have sent you an email with the instructions to reset your password.</Text>
          <PrimaryButton text={ "Sign in" } disabled={gettingData}
            onClick={ () =>  gotToDrive()}
            styles={{
              root: {
                margin: '0 auto',
                width: '100px'
              }
            }}
          />
        </Stack>
        :
        <Stack tokens={{childrenGap: 24}}>

          <Text variant={'xLarge'} className="lead">
            Forgot your password?
          </Text>

          { alert && alert.message &&
              <MessageBar
                messageBarType={alert.type}
                isMultiline={true}
                onDismiss={ () => setAlert({message: "", type: "", status: undefined}) }
                dismissButtonAriaLabel="Close"
                >
                { alert.message }
              </MessageBar>
          }

          <form name="form" onSubmit={handleSubmit(passwordReset)} className="mt-3">
              <Stack tokens={{childrenGap: 12}}
              styles={{
                  root: {
                    textAlign: 'left'
                  }
                }}
              >
                  <Controller
                      control={control}
                      name="email"
                      render={({ field }) => (
                        <>
                          <Label className='pb-0 mb-0'>Enter your email address:</Label>
                          <TextField {...field} underlined type="email" placeholder="Email" defaultValue="" required styles={{root: {label: {width: '150px'}}, field: {padding: 0}}} />
                        </>
                      )}
                  />

                  {(window.location.host === 'account.dev.synergyos.com' || window.location.host === 'account.app.synergyos.com') && <Controller
                    control={control}
                    name="serverDomain"
                    render={({ field }) => (
                      <Controller
                        control={control}
                        name="companyDomain"
                        render={({ field }) => (
                          <>
                            <Label className='pb-0 mb-0'>Company domain:</Label>
                            <TextField {...field} underlined id="customServerInput" type="text" placeholder="Example: synergyos.com" required className="mb-3" styles={{root: {label: {width: '150px'}}, field: {padding: 0}}} />
                          </>
                        )}
                      />
                    )}
                  />}

                  <Stack
                    styles={{
                      root: {
                        display: "flex",
                        flexDirection: 'column',
                        flexFlow: 'unset',
                        justifyContent: 'flex-end',
                        marginTop: '32px !important'
                      }
                    }}
                  >
                      <PrimaryButton text={ "Next" } disabled={gettingData} type="submit"
                        styles={{
                          root: {
                            width: '100px'
                          }
                        }}
                      />
                      {gettingData &&
                        <Spinner size={SpinnerSize.small} styles={{ root: { marginLeft: '12px' } }}/>
                      }
                  </Stack>
                  <Text variant={'small'}
                    styles={{
                      root: {
                        marginTop: '32px !important',
                        textAlign: 'center'
                      }
                    }}
                  >
                    <a href={'https://drive.' + (process.env.NODE_ENV === "development" ? "synergyos.com" : window.location.host.split('.').slice(1).join('.'))}>
                      Go to Drive
                    </a>
                  </Text>
              </Stack>
          </form>
        </Stack>
      }


      </Stack>
    </>
  );
}