import { IconButton, Link, Panel, PanelType, SearchBox } from '@fluentui/react';
import * as React from 'react';
import UAParser from 'ua-parser-js';

const apps = [
  {
    key: 'home',
    name: 'Home',
    icon: 'home_icon.png',
    devApp: false,
  },
  {
    key: 'drive',
    name: 'Drive',
    icon: 'drive_icon.png',
    devApp: false,
  },
  {
    key: 'workflow',
    name: 'Workflow',
    icon: 'workflow_icon.png',
    devApp: true,
  },
  {
    key: 'messages',
    name: 'Messages',
    icon: 'messages_icon.png',
    devApp: true,
  },
  {
    key: 'portal',
    name: 'Client portal',
    icon: 'portal_icon.png',
    devApp: true,
  },
  {
    key: 'account',
    name: 'Account',
    icon: 'drive_icon.png',
    devApp: false,
  },
  {
    key: 'admin',
    name: 'Admin',
    icon: 'drive_icon.png',
    devApp: false,
  }
]

export interface Props {

}

export interface States {
  searchMenu: boolean;
  searchInside: boolean;
  searchType: string;
  searchByName: string;
  searchLocation: string;
  advancedSearchName: string;
  advancedSearchContent: string;
  appsPanel: boolean;
  appFilter: string;
  filteredApps: any[];
}

export class Titlebar extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this.state = {
      searchMenu: false,
      searchInside: true,
      searchType: 'ALL',
      searchByName: 'searchByName',
      searchLocation: localStorage.searchLocation || 'everywhere',
      advancedSearchName: '',
      advancedSearchContent: '',
      appsPanel: false,
      appFilter: '',
      filteredApps: apps.slice(0)
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    document.addEventListener("keydown", this._handleKeyDown);
  }
  
  public componentDidUpdate(prevProps:any) {

  }

  public componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("keydown", this._handleKeyDown, false);
  }

  public render() {
    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;

    const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : 'synergyos.com';
    const devAppsEnabled = domain === 'greendation.org' || domain === 'synergyos.com'

    return(
      <div id="top-titlebar" className="d-flex" style={{width: '100vw'}}>
      <div className="d-flex title align-items-center mr-3" style={{minWidth: '25%'}}>
        <IconButton onClick={()=>{this._isMounted && this.setState({appsPanel: true})}} className='header-waffle' iconProps={{iconName:'WaffleOffice365'}} styles={{root: {width: '20px', height: '20px'}, icon: {fontSize: 16}}}/>
        <img src={ process.env.PUBLIC_URL + "/img/account_icon.png" } className="img-fluid mx-3" alt="Synergy Workflow logo" />
        <span>
          <Link to={"/"}>
            Account
          </Link>
        </span>
      </div>
      <div className='d-flex align-items-center ml-auto pr-2' style={{minWidth: '25%', marginLeft: 'auto'}}>
        <a className='d-flex align-items-center ml-auto' href='https://www.synergyos.com'><img src="/img/synergy_logo_white.png" height="14px" alt="Synergy Inc. logo" /></a>
      </div>

      <Panel
          isOpen={this.state.appsPanel}
          onDismiss={()=>{this._isMounted && this.setState({appsPanel: false})}}
          closeButtonAriaLabel="Close"
          isLightDismiss
          hasCloseButton={false}
          //styles={{main: {inset:'0px auto 0px 0px'}}}
          styles={{commands: {padding: 0, marginTop: '0'}, main: {marginTop: '0', background: '#faf9f8'}, content: {padding: '16px'}}}
          type={PanelType.customNear}
          customWidth={'340px'}
          onRenderHeader={()=>{
            return (
              <div className='d-flex align-items-center' style={{height: '40px'}}>
                <IconButton onClick={()=>{this._isMounted && this.setState({appsPanel: false})}} className='panel-waffle' iconProps={{iconName:'WaffleOffice365'}} styles={{icon: {fontSize: 16}}}/>
                {localStorage.getItem('companyLogo2') && localStorage.getItem('companyLogo2') !== 'null' ? <img className='ml-3' alt="SynergyOS" src={'data:image/png;base64,'+localStorage.getItem('companyLogo2') } style={{height: '40px'}}/>
                : <img className='ml-3' alt="SynergyOS" src={process.env.PUBLIC_URL + '/img/synergy_logo_colour.png'} style={{height: '16px'}}/> }
              </div>
            )
          }}
        >
          <div className='d-flex' style={{flexDirection: 'column'}}>
          <SearchBox className='mb-3' placeholder='Search' value={this.state.appFilter} onChange={(e,v)=>{this.filterApps(v)}} iconProps={{iconName: "Filter"}} styles={{root: {background: 'transparent', borderColor: '#bfbfbf'}}} />
            <span className='mb-3' style={{fontWeight: 600, fontSize: 18}}>Apps</span>

            {this.state.filteredApps && this.state.filteredApps.map((app)=>{
              return(
                app.devApp === false ? 
                <a key={app.key} href={'https://'+app.key+'.'+domain} className='app-selection d-flex align-items-center'>
                  <div className='app-icon d-flex'>
                      <img className='m-auto' src={process.env.PUBLIC_URL + "/img/"+app.key+"_icon.png"}/>
                  </div>
                  <span className='text-center'>{app.name}</span>
                </a>
                :
                <a key={app.key} href={devAppsEnabled ? 'https://'+app.key+'.'+domain : '#'} className={'app-selection d-flex align-items-center' + (devAppsEnabled ? '' : ' disabled')}>
                <div className='app-icon d-flex'>
                    <img className='m-auto' src={process.env.PUBLIC_URL + "/img/"+app.key+"_icon.png"}/>
                </div>
                <span className='text-center'>{app.name}</span>
              </a>
              )
            })}
          </div>
        </Panel>

    </div>
    )
  }

  private _handleKeyDown = (event) => {
    var parser = new UAParser.UAParser();
    var os = parser.getResult().os;
    if ((os.name === "Windows" && (event.ctrlKey || event.altKey) && event.keyCode === 70)
      || (os.name === "Mac OS" && (event.metaKey || event.altKey)  && event.keyCode === 70)) {
      event.preventDefault();
      this.openSearchMenu();
    }
  }

  private handleAdvancedSearchName = (ev: any, newValue?: string) => {
    this._isMounted && this.setState({
      advancedSearchName: newValue || ""
    });
  }

  private handleAdvancedSearchContent = (ev: any, newValue?: string) => {
    this._isMounted && this.setState({
      advancedSearchContent: newValue || ""
    });
  }


  private openSearchMenu() {
    this._isMounted && this.setState({searchMenu: true}, ()=>{
      this._isMounted && this.setState({
        advancedSearchName: '',
        advancedSearchContent: '',
        searchType: 'ALL',
        searchInside: true,
        searchByName: 'searchByName',
        searchLocation: (this.props.fileExplorerType === 'fileExplorer' || this.props.fileExplorerType === 'viewer') ? (localStorage.searchLocation || 'everywhere') : 'everywhere'
      },()=>{
        setTimeout(()=>document.getElementById('searchName') && document.getElementById('searchName')!.focus(),100)
      })
    })
  }

  private filterApps(value):any {
    if (value !== '') {
      let filteredApps = apps.filter((app)=>{
        return app.name.toLowerCase().includes(value.toLowerCase())
      })
      this._isMounted && this.setState({appFilter: value || '', filteredApps: filteredApps})
    } else {
      this._isMounted && this.setState({appFilter: value || '', filteredApps: apps})
    }
  }

}
